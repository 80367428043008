// const { sub } = require("mozjpeg");

let PAGINATION_NUM = 1;
let TOTAL_CASE_STUDIES = 123;
let SELECTED_TAGS = [];
let JOBS_FILTERS = {
	functions: [],
	locations: [],
};
let PAGE_URL = window.location.href;

//menu scroll
function bindOnScrollEvent() {
	$(document)
		.off("scroll")
		.on("scroll", function() {
			window.innerWidth > 767 &&
				(addOrRemoveFillBg(),
				$(window).scrollTop() > 200
					? $(".scroll-top-wrapper").addClass("show")
					: $(".scroll-top-wrapper").removeClass("show"));
		});
}
function addOrRemoveFillBg() {
	$(window).scrollTop() > 0
		? $(".navbar.fixed-top")
				.removeClass("bg-trans")
				.addClass("bg-white")
		: $(".navbar.fixed-top")
				.removeClass("bg-white")
				.addClass("bg-trans");
}
function fillbgForSmallScreens() {
	window.innerWidth < 768 &&
		$(".navbar.fixed-top")
			.addClass("bg-white")
			.removeClass("bg-trans");
}
function scrollToTop() {
	(verticalOffset = "undefined" != typeof verticalOffset ? verticalOffset : 0),
		(element = $("body")),
		(offset = element.offset()),
		(offsetTop = offset.top),
		$("html,body").animate({ scrollTop: offsetTop }, 500, "linear");
}
bindOnScrollEvent(),
	fillbgForSmallScreens(),
	$(window).resize(function() {
		bindOnScrollEvent(),
			fillbgForSmallScreens(),
			window.innerWidth > 991 && addOrRemoveFillBg();
	});
/* Hamburgers JQuery */
var forEach = function(t, o, r) {
	if ("[object Object]" === Object.prototype.toString.call(t))
		for (var c in t)
			Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
	else for (var e = 0, l = t.length; l > e; e++) o.call(r, t[e], e, t);
};
var hamburgers = document.querySelectorAll(".hamburger");
if (hamburgers.length > 0) {
	forEach(hamburgers, function(hamburger) {
		hamburger.addEventListener(
			"click",
			function() {
				this.classList.toggle("is-active");
			},
			false
		);
	});
}

//add active in menu
$(".nav-item h5").on("click", function() {
	if (
		$(this)
			.parent()
			.hasClass("open")
	) {
		$(this)
			.parent()
			.removeClass("open");
	} else {
		$(".nav-item.open").removeClass("open");
		$(this)
			.parent()
			.addClass("open");
	}
});

//mega close click
$(".mega-services .close-btn").click(function() {
	$(".nav-item").removeClass("open");
});

//letsconnect
$("#modal-submit").click(function() {
	$(".thanks-modal").show();
	$(".modal").addClass("blue");
	$(".initial-modal").hide();
});

$(document).ready(function() {
	var screenWidth = $(window).width();
	if (screenWidth <= 992) {
		$(".m-nav li a").click(function() {
			$(".mega-services").addClass("slideLeft");
		});
		$(".service-card-title").click(function() {
			$(".mega-services").removeClass("slideLeft");
		});
	}
});
$(".mega-menu-list-title").click(function() {
	$(".secondary li a").removeClass("active");
});
$(".secondary li a").click(function() {
	$(".mega-menu-list-title").removeClass("active");
});

//footer mobile accordion
$(document).ready(function() {
	var windowWidth = $(window).width();
	if (windowWidth >= 576) {
		$(".footer-list").removeClass("collapse");
	}
});

const caseStudyHTML = (case_studies) => {
	let html = ``;

	case_studies.forEach(
		({ details, img, title, trend_data, trend_text, tags }) => {
			let allTags = ``;
			tags.forEach(
				(tag) =>
					(allTags += `<span ${
						SELECTED_TAGS.includes(tag) ? 'class="selected"' : ""
					} onclick="tagHandler(event)">${tag}</span>`)
			);

			html += `
                <div class="col-lg-4 col-md-12 col-12 wow fadeInUp">
                    <a href=${details} class="case-card" target="_blank">
                        <figure>
                            <img class="img-fluid" src=${img}>
                        </figure>
                        <p class="case-title">${title}</p>
                        <p class="tranding ${trend_data.direction} text-green">${trend_data.number}</p>
                        <p class="tranding-text text-grey">${trend_text}</p>
                        <div class="categories grey">${allTags}</div>
                    </a>
                </div>
            `;
		}
	);

	return html;
};

const paginationHTML = (num_cs) => {
	let pages = ``;
	let page_num = num_cs ? 1 : PAGINATION_NUM;
	let case_studies = num_cs ? num_cs : TOTAL_CASE_STUDIES;

	for (let i = 0; i < Math.ceil(case_studies / 9); i++) {
		pages += `
            <li class="page-item ${i + 1 === page_num ? "active" : ""}">
                <div class="page-link">${i + 1}</div>
            </li>
        `;
	}

	let html = `
        <ul class="pagination" onclick="paginationHandler(event)">
            <li class="page-item"><div class="page-link"><span class="pagination-arrow left-arrow"></span></div></li>
            ${pages}
            <li class="page-item"><div class="page-link"><span class="pagination-arrow right-arrow"></span></div></li>
        </ul>
    `;

	return html;
};

const articlesHTML = (articles) => {
	let html = ``;

	articles.forEach(({ details, img, topic, title, trend_text, tags }) => {
		let allTags = ``;
		tags ? tags.forEach((tag) => (allTags += `<span>${tag}</span>`)) : null;

		html += `
                <div class="col-lg-4 col-md-12 col-12 wow fadeInUp">
                    <a href=${details} class="case-card" target="_blank">
                        <figure> 
                            <img class="img-fluid" src=${img}> 
                        </figure>
                        <p class="heading-tag text-blue">${topic}</p>
                        <p class="case-title">${title}</p>
                        <p class="tranding-text text-grey">${trend_text}</p>
                        <div class="categories grey">${allTags}</div>
                    </a>
                </div>
            `;
	});

	return html;
};

const jobsHTML = (jobs) => {
	let html = ``;

	jobs.forEach(({ details, designation, fnc, place, time, role }) => {
		let job_role = role ? ` · + ${role}` : "";

		html += `
			<div class="col-lg-4 col-md-6 col-sm-12 p-2 wow fadeInUp" data-wow-delay="0.1s">
				<a class="job-card" href=${details}>
                    <p>
                        <span class="title">${designation}</span>
                        <span class="sub-title">
                            ${place} · ${fnc} · ${time} ${job_role}
                        </span>
                    </p>
                </a>
			</div>	
            `;
	});

	return html;
};

const loadJobs = () => {
	fetch("./assets/json/data.json")
		.then((response) => response.json())
		.then(
			(data) =>
				(document.querySelector(".job-list-body").innerHTML = jobsHTML(
					data.jobs
				))
		);
};

// Load case studies first time the page loads
const loadCaseStudies = () => {
	fetch("./assets/json/data.json")
		.then((response) => response.json())
		.then((data) => {
			document.querySelector(".case-body").innerHTML = caseStudyHTML(
				data.case_studies.slice(0, 9)
			);
			document.querySelector(".pagination-body").innerHTML = paginationHTML();
		});
};

// Load articles
const loadArticles = () => {
	fetch("./assets/json/data.json")
		.then((response) => response.json())
		.then(
			(data) =>
				(document.querySelector(
					"#articles .case-body"
				).innerHTML = articlesHTML(data.articles))
		);
};

// load the right 9 case studies based on page number
const paginationHandler = (event) => {
	let leftArrow = document.querySelector(".left-arrow");
	let rightArrow = document.querySelector(".right-arrow");

	// go left
	if (event.target.contains(leftArrow))
		PAGINATION_NUM >= 2 ? --PAGINATION_NUM : null;
	// go right
	else if (event.target.contains(rightArrow))
		PAGINATION_NUM < TOTAL_CASE_STUDIES / 9 ? ++PAGINATION_NUM : null;
	// update pagination number
	else PAGINATION_NUM = parseInt(event.target.textContent);

	// load case studies and the pagination HTML
	fetch("./assets/json/data.json")
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			document.querySelector(".case-body").innerHTML = caseStudyHTML(
				data.case_studies.slice(9 * (PAGINATION_NUM - 1), PAGINATION_NUM * 9)
			);
			document.querySelector(".pagination-body").innerHTML = paginationHTML();
		});

	document.querySelector(".case-body").scrollIntoView(true);
};

const tagHandler = (event) => {
	let pagination = document.querySelector(".pagination-body");
	let tag_name = event.target.textContent;

	if (event.target.nodeName === "SPAN") {
		event.stopPropagation();
		event.preventDefault();

		// add/remove the tag
		if (!SELECTED_TAGS.includes(event.target.textContent))
			SELECTED_TAGS.push(event.target.textContent);
		else SELECTED_TAGS = SELECTED_TAGS.filter((tag) => tag !== tag_name);

		let tags = "";
		for (let i = 0; i < SELECTED_TAGS.length; i++) {
			if (i == 0) tags += SELECTED_TAGS[i];
			else tags += " | " + SELECTED_TAGS[i];
		}

		document.querySelector(".case-tag-heading").textContent = tags;
	}

	fetch("./assets/json/data.json")
		.then((response) => response.json())
		.then((data) => {
			let filteredCS;
			let targetTags = [];

			// first time selecting a tag
			if (SELECTED_TAGS.length === 0) targetTags.push(tag_name);
			else targetTags = SELECTED_TAGS;

			// case studies that contain all of SELECTED_TAGS
			filteredCS = data.case_studies.filter((cs) =>
				SELECTED_TAGS.every((val) => cs.tags.includes(val))
			);

			document.querySelector(".case-body").innerHTML = caseStudyHTML(
				filteredCS
			);

			let all_CS_link = "";
			if (PAGE_URL.includes("insights")) all_CS_link = "./insights";
			else if (PAGE_URL.includes("case-studies"))
				all_CS_link = "./case-studies";

			pagination.innerHTML = `
                <a class="normal-btn black-btn" href="${all_CS_link}">
                    all case studies
                </a>
            `;
			pagination.style.marginTop = "0px";
		});

	document.querySelector(".case-tag-heading").scrollIntoView(true);
};

// Load case studies and pagination HTML

if (PAGE_URL.includes("case-studies") || PAGE_URL.includes("insights")) {
	loadCaseStudies();
} else if (PAGE_URL.includes("join-us")) {
	loadJobs();
}

if (PAGE_URL.includes("who-we-are")) {
	let section = document.querySelector(".who-video");
	let video = section.querySelector("video");
	let playButton = section.querySelector(".play-button");

	video.onplay = () => {
		playButton.classList.remove("active");
	};

	video.onpause = () => {
		playButton.classList.add("active");
	};

	playButton.addEventListener("click", () => {
		video.play();
	});
}

// filter jobs list by functions
const jobsFilter = () => {
	let selectedFunction = document.getElementById("jobsFilterFunction").value;
	let selectedLocation = document.getElementById("jobsFilterLocation").value;

	// get selected function/location names and save
	JOBS_FILTERS.functions.length = 0;
	JOBS_FILTERS.locations.length = 0;

	if (selectedFunction !== "false") JOBS_FILTERS.functions.push(selectedFunction);
	if (selectedLocation !== "false") JOBS_FILTERS.locations.push(selectedLocation);

	if (selectedFunction !== "false" || selectedLocation !== "false") {
		convertJobsFilterButton();
	} else {
		resetJobsFilterButton();
	}

	fetch("./assets/json/data.json")
		.then((response) => response.json())
		.then(
			(data) =>
				(document.querySelector(".job-list-body").innerHTML = jobsHTML(
					data.jobs.filter((job) => {
						// filter by function
						if (JOBS_FILTERS.functions.length > 0) {
							// filter by function & location
							if (JOBS_FILTERS.locations.length > 0) {
								return (
									JOBS_FILTERS.functions.includes(job.fnc) &&
									JOBS_FILTERS.locations.includes(job.location)
								);
							}
							return JOBS_FILTERS.functions.includes(job.fnc);
						}

						// filter by location
						else if (JOBS_FILTERS.locations.length > 0)
							return JOBS_FILTERS.locations.includes(job.location);
						// no filter
						else return true;
					})
				))
		);
};

const clearJobFilter = () => {
	document.getElementById("jobsFilterFunction").value = "false";
	document.getElementById("jobsFilterLocation").value = "false";
	resetJobsFilterButton();
	jobsFilter();
}

const setCountryJobFilter = (region) => {
	window.location.href = "#job-opening";
	document.getElementById("jobsFilterFunction").value = "false";
	document.getElementById("jobsFilterLocation").value = region;	
	jobsFilter();
}

const convertJobsFilterButton = () => {
	const clearButton = document.getElementById("jobsFilterButton")
	clearButton.innerHTML = "clear filter"
	clearButton.classList.remove("blue-btn");
	clearButton.onclick = clearJobFilter;
}

const resetJobsFilterButton = () => {
	const clearButton = document.getElementById("jobsFilterButton")
	clearButton.innerHTML = "filter"
	clearButton.classList.add("blue-btn");
}

//testimonial carousel
$(document).ready(function() {
	var owl = $(".service-tech-icon");
	var commonOwl = $(".owl-carousel");
	var engagement = $(".engagement-slide");
	var testimonial = $(".testimonial-carousel");
	var engagementSlide = $(".engagement-slide");
	var awardSlide = $(".award-carousel");

	// Custom Navigation Events
	$(".btn-next").click(function() {
		owl.trigger("next.owl.carousel");
		commonOwl.trigger("next.owl.carousel");
		engagement.trigger("next.owl.carousel");
		testimonial.trigger("next.owl.carousel");
	});
	$(".btn-prev").click(function() {
		owl.trigger("prev.owl.carousel");
		commonOwl.trigger("prev.owl.carousel");
		engagement.trigger("prev.owl.carousel");
		testimonial.trigger("prev.owl.carousel");
	});
	$(".engagement-next").click(function() {
		engagementSlide.trigger("next.owl.carousel");
	});
	$(".engagement-prev").click(function() {
		engagementSlide.trigger("prev.owl.carousel");
	});
	$(".award-next").click(function() {
		awardSlide.trigger("next.owl.carousel");
	});
	$(".award-prev").click(function() {
		awardSlide.trigger("prev.owl.carousel");
	});
	$(".slider-content").owlCarousel({
		loop: true,
		dots: false,
		nav: false,
		margin: 40,
		autoplay: false,
		responsiveClass: true,
		animateOut: "fadeOut",
		animateIn: "fadeIn",
		responsive: {
			0: {
				items: 1,
			},
			480: {
				items: 1,
			},
			1000: {
				items: 1,
			},
		},
	});
	$("#tech-icon").owlCarousel({
		loop: false,
		dots: false,
		nav: false,
		margin: 40,
		responsiveClass: true,
		autoplay: false,
		responsive: {
			0: {
				items: 3,
			},
			480: {
				items: 3,
			},
			1000: {
				items: 5,
			},
		},
	});
	$("#location-carousel").owlCarousel({
		loop: false,
		dots: false,
		nav: false,
		center: true,
		margin: 24,
		responsiveClass: true,
		autoplay: false,
		responsive: {
			0: {
				items: 2,
			},
			480: {
				items: 2,
			},
			1000: {
				items: 4.5,
			},
		},
	});
	$(".award-carousel").owlCarousel({
		loop: false,
		dots: false,
		nav: false,
		margin: 48,
		responsiveClass: true,
		autoplay: false,
		responsive: {
			0: {
				items: 2,
			},
			480: {
				items: 2,
			},
			1000: {
				items: 4,
			},
		},
	});
	$("#video-slider").owlCarousel({
		loop: false,
		dots: false,
		nav: false,
		margin: 24,
		responsiveClass: true,
		autoplay: false,
		center: true,
		video: true,
		responsive: {
			0: {
				items: 1,
				center: false,
			},
			480: {
				items: 1,
				center: false,
			},
			1000: {
				items: 2,
			},
		},
	});
	$(".engagement-slide").owlCarousel({
		loop: false,
		dots: false,
		nav: false,
		margin: 24,
		responsiveClass: true,
		autoplay: false,
		responsive: {
			0: {
				items: 1,
				loop: true,
			},

			600: {
				items: 2,
				loop: true,
			},
			1000: {
				items: 3,
			},
		},
	});
	$(".testimonial-carousel").owlCarousel({
		loop: true,
		dots: false,
		nav: false,
		autoplay: false,
		items: 1,
	});
});
